.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app {
  background-color: var(--maingr); /* Adjust this to match your theme */
  height: 100vh;
  width: 100vh;
  overflow: hidden; /* Ensure no overflow issues */
}

/* My added css */
:root {
  /* Color Theme Swatches in RGBA */
  --navgr: #2d2c32;
  --embedgr: #2e3136;
  --maingr: #313338;
  --gr: #424549;
  --hovergr: #2f2f37;
  --navhovergr: #414149;
  --darkscrollarea: #2d2c32;
  --darkscrollthumb: #1a191f;
  --accentmaybe: #7289da;
  --text: white;
  --gap: 16px;
  --bordercolor: #2c2c34;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--text);
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  
}

/* *** CSS GRID TEMPLATE AREAS */

/* TEST PAGE */

.homepagecontent {
  display: grid;
  grid-template-areas: 
    "sidebar content"
    "footer footer";
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 50px;
  gap: 10px;
  height:100%;
  margin-right: var(--gap);
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: var(--maingr);
}



 /* THIS IS THE FORM SECTION */

.sidebar {
  grid-area: sidebar;
  background-color: var(--navgr);
  padding: 2rem 1rem;
  top: 0;
  margin-top: 18px;
  padding-top: 40px;
  border: 1px solid var(--bordercolor);
  border-radius: 8px;
  
}

.filter-title {
  padding-bottom: 25px;

}

.filter-toggle {
  display:none;
}

/* Base styles for text input boxes : contains right side*/
.sidebar .filterForm .filterTextItem {
  display:block;
  text-decoration: none;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  color: #1a191f;
}

/* Base styles for text input boxes : spacing*/
.sidebar .filterForm input[type="text"],
.sidebar .filterForm input[type="number"] {
  border: 2px solid var(--embedgr);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  color:black;
}

/* Focus styles for text inputs : focus color*/
.sidebar .filterForm input[type="text"]:focus,
.sidebar .filterForm input[type="number"]:focus {
  border: 2px solid var(--accentmaybe);
  outline: none;
  color: black;
}

/* Fixes weird spacing and aligns box and label */
.sidebar .filterForm .filterItemWrapper {
  display: flex;
  align-items: center;
  padding-top: var(--gap);
  padding-bottom: var(--gap);
  padding-left: 10px;
  padding-right: 10px;
}

/* Fixes weird spacing and aligns box and label */
.sidebar .filterForm .filterItemWrapper:hover {
  background-color: var(--navhovergr);
}

/* Style checkboxes : adds spacing between box and label */
.sidebar .filterForm .filterItemWrapper input[type="checkbox"] {
  margin-right: 10px;
}


/* Optional: style the label text : pointer on label */
.sidebar .filterForm .filterItemWrapper label {
  cursor: pointer;
}

/* Focus styles for checkbox inputs : active color change */
.sidebar .filterForm input[type="checkbox"] {
  accent-color: var(--accentmaybe); 
}

 /* THIS IS THE  END OF FORM SECTION */


 
#infiniteScrollContainer {
  grid-area: content;
  overflow: auto;
  height: 100vh;
  padding: var(--gap);
  /* border: 1px solid var(--bordercolor);
  border-radius: 8px; */
}


.scrollableArea {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  background-color: var(--maingr);
  height: 90vh;
  border: 1px solid var(--bordercolor);
  border-radius: 8px;
}

@media (max-width: 768px) {
  .homepagecontent {
    display: grid;
    grid-template-areas:
      "sidebar"
      "content"
      "footer";
    grid-template-columns: 1fr;
    gap: 10px;
    height: 100%;
    padding-top: 2.5rem;
    margin-right: 5px;
  }

  #infiniteScrollContainer {
    padding: 2.5px;
  }

  .scrollableArea {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    background-color: var(--maingr);
    height: 90vh;
    border: 1px solid var(--bordercolor);
    border-radius: 8px;
  }

  .sidebar {
    display: none; /* Hide by default */
    padding: 10px;
    margin-top: 8px;
    width: 100%;
  }

  .sidebar.visible {
    display: block; /* Show when the 'visible' class is added */
    z-index: 999;
    position:absolute;
    margin-top: 50px;
  }

  .filter-toggle {
    display: block;
    position: fixed;
    top: 10px;
    right: 3rem;
    background-color: var(--navgr);
    border: none;
    border-radius: 4px;
    padding: 2.5px 10px;
    cursor: pointer;
    z-index: 1000;
    border: 1px solid var(--darkscrollthumb);
  }

  .filter-toggle img {
    cursor: pointer;
    width: 40px; /* Adjust size as needed */
    height: auto;
  }

  .sidebar .filterForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 5px;
  }

  .sidebar .filterForm .filterTextItem,
  .sidebar .filterForm .filterItemWrapper {
    grid-column: span 1; /* Span one column */
    padding: 8px;
  }

  /* Ensure text inputs span full width of their container */
  .sidebar .filterForm input[type="text"],
  .sidebar .filterForm input[type="number"] {
    width: 100%;
  }

  /* Ensure checkboxes are aligned properly */
  .sidebar .filterForm .filterItemWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .filter-title {
    padding-bottom: 5px;
  }

  #framename {
    font-size: 18px;
  }

  .prices-text {
    font-size: 12px;
    
  }

  .low-price {
    color: var(--accentmaybe); /* Ensure text is readable */
    padding: 2px;
    flex: 1; /* Ensure both items take equal space */
    text-align: center;
  }
  
  .high-price {
    /* color: white;  */
    padding: 0;
    flex: 1; /* Ensure both items take equal space */
    text-align: center;
    color: var(--accentmaybe);
  }

  .footer {
    position:fixed;
    bottom:0;
  }
}

/* Landscape mode */
/* @media (orientation: landscape) {
  .pagecontent {
    display: grid;
    grid-template-areas:
      "sidebar"
      "content";
    grid-template-columns: 1fr;
    gap: 10px;
    height: 100%;
    padding-top: 3.5rem;
    width: 825px;
  }

  .scrollableArea {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    background-color: var(--maingr);
    height: 90vh;
    border: 1px solid var(--bordercolor);
    border-radius: 8px;
    width: 800px;
  } */
/* 
  .sidebar {
    display: none; Hide by default
    z-index: 999;
  }

  .sidebar.visible {
    display: block; Show when the 'visible' class is added
    z-index: 999;
    position:absolute;
  }

  .filter-toggle {
    display: block;
    position: fixed;
    top: 1rem;
    right: 5rem;
    background-color: var(--navgr);
    border: none;
    border-radius: 4px;
    padding: 1rem;
    cursor: pointer;
    z-index: 1000;
    border: 1px solid var(--darkscrollthumb); 
  }

  .filter-toggle img {
    cursor: pointer;
    width: 30px; Adjust size as needed
    height: auto;
  }

  .sidebar .filterForm {
    display: grid;
    grid-template-columns: repeat(4, 1fr); Two columns
    gap: 5px;
  }

  .sidebar .filterForm .filterTextItem,
  .sidebar .filterForm .filterItemWrapper {
    grid-column: span 1; Span one column
  } */

  /* Ensure text inputs span full width of their container */
  /* .sidebar .filterForm input[type="text"],
  .sidebar .filterForm input[type="number"] {
    width: 100%;
  } */

  /* Ensure checkboxes are aligned properly */
  /* .sidebar .filterForm .filterItemWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .filter-title {
    padding-bottom: 15px;
  }

  .prices-text {
    font-size: 13px;
  }
} */

/* SMALL SCREEN STUFF */


  /* Base styles for text input boxes : contains right side*/
.sidebar .filterForm .filterTextItem {
  display: flex;
  text-decoration: none;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  }

/* Fixes weird spacing and aligns box and label */
.sidebar .filterForm .filterItemWrapper {
  display: flex;
  align-items: center;
  padding-top: var(--gap);
  
}

/* SCROLL CUSTOMIZATION */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--darkscrollarea);
  /* border-radius: 100vw;
  margin-block: .5em; 
  had to remove because it makes the top and bottom corrners of scroll white*/
}

::-webkit-scrollbar-thumb {
  background: var(--darkscrollthumb);
  border-radius: 100vw;
}

main::-webkit-scrollbar {
  width: 7px;
}

main::-webkit-scrollbar-track {
  background: var(--darkscrollarea);
}

main::-webkit-scrollbar-thumb {
  background: var(--darkscrollthumb);
  border-radius: 100vw;
}


/* SCROLL CUSTOMIZATION */

/* main page */

#content {
  background: var(--maingr);
  /*overflow-y: auto; /* Enable scrolling for content */
}

aside {
  /* background-color: var(--navgr); */
  padding: var(--gap);
}

#framename {
  color:var(--accentmaybe);
  font-weight: 500;
}

/* App.css */
.image-container {
  padding: var(--gap);
  /* box-shadow: 10px 10px 30px rgba(0,0,0,0.1) */
}

/* App.css */
.scroll-item:hover {
  background: var(--hovergr);
  border: 1px solid var(--bordercolor);
  border-radius: 8px;
}

.image-container img {
  width: 100%; /* Image takes 100% of the container's width */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure image doesn't exceed container size */
}

.scroll-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 32px;
  border: 1px solid var(--bordercolor);
  border-radius: 4px;
  
}

.prices-text {
  display: flex;
  flex-direction: row; /* Display items side by side */
  /* gap: var(--gap); */
  padding-bottom: var(--gap);
  width: 100%;
  
}

.low-price {
  color: var(--accentmaybe); /* Ensure text is readable */
  padding: 2px;
  flex: 1; /* Ensure both items take equal space */
  text-align: center;
}

.high-price {
  /* color: white;  */
  padding: 2px;
  flex: 1; /* Ensure both items take equal space */
  text-align: center;
  color: var(--accentmaybe);
}

/* API Page CSS */

.apipagecontent {
  display: grid;
  grid-template-areas: 
    "header header"
    "overview using"
    "response features"
    "footer footer";
  grid-template-columns: 2fr 2fr;
  gap: 20px;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

@media (max-width: 768px) {
  .apipagecontent {
    display: grid;
    grid-template-areas:
      "header"
      "overview"
      "using"
      "response"
      "features"
      "footer";
    grid-template-columns: 1fr;
    gap: 10px;
    height: 100%;
    padding-top: 2.5rem;
    margin-right: 5px;
  }
}


section {
  border: 1px solid var(--bordercolor);
  border-radius: 4px;

}

section text {
  padding: 10px;
}

section:hover {
  background: var(--hovergr);
  border: 1px solid var(--bordercolor);
  border-radius: 8px;
}

.h1header {
  grid-area: header;
  color: white;
  margin-bottom: 10px;
}

.overview {
  grid-area: overview;
}

.using {
  grid-area: using;
}

.response {
  grid-area: response;
}

.features {
  grid-area: features;
}



li {
  list-style-type: none;
}

.h2header {
  color: var(--accentmaybe);
  margin-bottom: 20px;
}

code, pre {
  background: var(--hovergr);
  padding: 10px;
  border-radius: 5px;
  display: block;
  overflow-x: auto;
}

.footer {
  grid-area: footer;
  background: var(--hovergr);
  border-top: 1px solid var(--navgr);
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  text-align: center;
}

.footer section {
  flex: 1; 
  text-align: center; 
}

.footer a {
  color: inherit;
  text-decoration: none; 
}

.footer a:hover {
  text-decoration: underline; 
}

.apifooter {
  grid-area: footer;
  background: var(--hovergr);
  border-top: 1px solid var(--navgr);
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  height:5vh;
  align-items: center;
  text-align: center;;
}

/* -------------------------------------------------------------------- */
.morphpagecontent {
  background-color: #313338;
}